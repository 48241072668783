<template>
  <v-app>
    <v-main class="background">
      <v-container class="fill-height">
        <welcome-form></welcome-form>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import  WelcomeForm from "@/components/WelcomeForm.vue";

export default {
  name: "Home",
  components: {
    WelcomeForm
  },
};
</script>

<style scoped>
.background{
  background: url('~@/assets/background.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
