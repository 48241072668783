<template>
  <v-row justify="center">
      <v-col cols="12" lg="5">
          <v-card class="tw-rounded-xl">
              <v-card-title class="text-capitalize">
                  <h1 class="text-h6 tw-blue-600"><span class="tw-text-red-600">join </span> with us</h1>
                  <v-spacer></v-spacer>
                  <v-img src="@/assets/logo.png" max-width="100"></v-img>
              </v-card-title>
              <v-card-text>
                  <v-form>
                      <v-text-field label="Full Name" class="mb-n4" outlined prepend-inner-icon="mdi-account" color="#EB0A1E" single-line dense></v-text-field>
                      <v-text-field label="Email" single-line class="mb-n4" outlined type="email"  prepend-inner-icon="mdi-email" color="#EB0A1E" dense></v-text-field>
                      <v-text-field label="Phone" single-line class="mb-n4" outlined prepend-inner-icon="mdi-phone" color="#EB0A1E"  dense></v-text-field>
                      <v-text-field label="Password" single-line outlined class="mb-n4" type="password" prepend-inner-icon="mdi-lock"  color="#EB0A1E" dense></v-text-field>
                      <v-text-field label="Confirm Password" class="mb-n4" single-line outlined type="password" prepend-inner-icon="mdi-lock"  color="#EB0A1E"  dense></v-text-field>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="#EB0A1E" width="100%" class="tw-rounded-lg" depressed dark>Daftar</v-btn>
                      </v-card-actions>
                  </v-form>
              </v-card-text>
          </v-card>
      </v-col>
  </v-row>
</template>

<script>
export default {

}
</script>